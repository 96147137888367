import {h, resolveComponent} from 'vue'
import {createRouter, createWebHashHistory} from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'
import Mgr from '../services/SecurityServices';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      }
    ],
  },
  {
    path: '/persons',
    redirect: '/persons/overview',
    name: 'Persons Overview',
    component: DefaultLayout,
    children: [
      {
        path: 'overview',
        name: 'Persons Overview',
        component: () => import('@/views/persons/Overview'),
      },
      {
        path: 'edit/:id',
        name: 'Edit Person',
        component: () => import('@/views/persons/New'),
      },
      {
        path: 'new',
        name: 'New Person',
        component: () => import('@/views/persons/New'),
      }
    ],
  },
  {
    path: '/reports',
    redirect: '/reports/latest',
    name: 'Reports Overview',
    component: DefaultLayout,
    children: [
      {
        path: '/reports/latest',
        name: 'Latest Activities',
        component: () => import('@/views/reports/Movements'),
      },
      {
        path: '/reports/onsite',
        name: 'Onsite Personnel',
        component: () => import('@/views/reports/OnSiteNow'),
      },
      {
        path: '/reports/manual',
        name: 'Manual Entry',
        component: () => import('@/views/reports/Manual'),
      },
      {
        path: '/reports/timesheet',
        name: 'Timesheet',
        component: () => import('@/views/reports/Timesheet'),
      },
      {
        path: '/reports/payroll',
        name: 'Payroll Export',
        component: () => import('@/views/reports/Payroll'),
      },
      {
        path: '/reports/devices',
        name: 'Device Status',
        component: () => import('@/views/reports/Routers'),
      }
    ],
  },
  {
    path: '/settings',
    redirect: '/settings/settings',
    name: 'Settings',
    component: DefaultLayout,
    children: [
      {
        path: '/settings/settings',
        name: 'Settings',
        component: () => import('@/views/settings/Settings'),
      }
    ],
  },
  {
    path: '/sites',
    redirect: '/sites/overview',
    name: 'Jobsites Overview',
    component: DefaultLayout,
    children: [
      {
        path: 'overview',
        name: 'Jobsites Overview',
        component: () => import('@/views/sites/Overview'),
      },
      {
        path: 'edit/:id',
        name: 'Edit Jobsite',
        component: () => import('@/views/sites/New'),
      },
      {
        path: 'new',
        name: 'New Jobsite',
        component: () => import('@/views/sites/New'),
      }
    ],
  },
  {
    path: '/users',
    redirect: '/users/overview',
    name: 'Users Overview',
    component: DefaultLayout,
    children: [
      {
        path: 'overview',
        name: 'Users Overview',
        component: () => import('@/views/users/Overview'),
      },
      {
        path: 'edit/:id',
        name: 'Edit Users',
        component: () => import('@/views/users/New'),
      },
      {
        path: 'new',
        name: 'New Users',
        component: () => import('@/views/users/New'),
      }
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '401',
        name: 'Error 401 Authenticated Required',
        component: () => import('@/views/pages/Page401'),
      },
      {
        path: '404',
        name: 'Error 404 Page Not Found',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Error 500 Internal Server Error',
        component: () => import('@/views/pages/Page500'),
      }
    ],
  },
  {name: 'Page Not Found', path: '/:pathMatch(.*)*', component: () => import('@/views/pages/Page404')},
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return {top: 0}
  },
})

router.beforeEach(async (to) => {
  const publicPages = ['/pages/login', '/pages/401', '/pages/404', '/pages/500'];
  const authRequired = !publicPages.includes(to.path);
  if (authRequired) {
    const mgr = new Mgr();
    const isAuthed = await mgr.getSignedIn();
    if (!isAuthed) {
      return mgr.signIn().then(() => {
        return true;
      }).catch(() => {
        return false;
      });
    } else {
      return true;
    }
  }
})

router.afterEach((to, from, failure) => {
  if (!failure) {
    document.title = to.name + " - BARS" || "Biometric Attendance Reporting Suite";
  }
})

export default router
