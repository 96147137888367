<template>
  <router-view/>
</template>

<script>
import {useGlobalStore} from "@/stores/globalStore"
import ApiServices from "@/services/ApiServices";

export default {
  setup() {
    const globalStore = useGlobalStore();
    const api = new ApiServices();

    return {
      globalStore,
      api
    }
  },
  data() {
    return {}
  },
  async created() {
    // Get the saved state from localStorage
    try {
      // Load Installation Info
      const sec = (await this.api.Securities.apiV1SecurityInstGet()).data;
      this.globalStore.$patch({
        companyName: sec.company,
        installationId: sec.instId,
        masterPwd: sec.masterpwd
      });

    } catch (e) {
      // If it fails it fails, no point saving
      console.log(e);
    }
  }
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
