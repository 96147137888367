<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="globalStore.burgerToggle()">
        <CIcon icon="cil-menu" size="lg"/>
      </CHeaderToggler>
      <CHeaderNav class="d-none d-md-flex me-auto">
        Steel Ally Contracts Pte. Ltd. <span class="text-muted ms-2">Biometric Attendance Reporting Suite</span>
      </CHeaderNav>
      <CHeaderNav class="ms-3 me-4">
        <AppHeaderDropdownAccnt/>
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

<script>
  import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
  import {useGlobalStore} from "@/stores/globalStore";

  export default {
    name: 'AppHeader',
    components: {
      AppHeaderDropdownAccnt,
    },
    setup() {
      const globalStore = useGlobalStore();
      return {
        globalStore
      }
    }
  }
</script>
