<template>
  <CSidebar
    position="fixed"
    :unfoldable="globalStore.sidebarUnfoldable"
    :visible="globalStore.sidebarVisible"
    @visible-change="(event) => globalStore.updateSidebarVisible(event)"
  >
    <CSidebarBrand>
      <img class="sidebar-brand-full"
           :src="require('@/assets/brand/secureax.svg')"
           :height="35"
      />
      <img class="sidebar-brand-narrow"
           :src="require('@/assets/brand/logo-sm.png')"
           :height="35"
      />
    </CSidebarBrand>
    <AppSidebarNav/>
    <span v-if="!globalStore.sidebarUnfoldable" class="px-1 small">
      {{ appversion }}
      <br/>
      {{ buildDate }}
    </span>
    <CSidebarToggler
      class="d-lg-flex"
      @click="globalStore.toggleSidebar()"
    />
  </CSidebar>
</template>

<script>
import {AppSidebarNav} from './AppSidebarNav'
import {useGlobalStore} from "@/stores/globalStore"

export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  setup() {
    const globalStore = useGlobalStore();
    return {
      globalStore
    }
  },
  data() {
    return {
      appversion: '',
      buildDate: ''
    }
  },
  mounted() {
    this.appversion = process.env.VUE_APP_VERSION;
    this.buildDate = process.env.VUE_APP_BUILDDATE;
  }
}
</script>
