<template>
  <AppSidebar v-if="signedIn"/>
  <div class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent" v-if="signedIn">
    <AppHeader/>
    <div class="body flex-grow-1 px-3">
      <CContainer fluid>
        <router-view/>
      </CContainer>
    </div>
    <AppFooter/>
  </div>
  <CAlert v-if="!signedIn" color="warning" class="m-4">
    You must be signed in to access this software
  </CAlert>
</template>

<script>
import {CContainer} from '@coreui/vue-pro'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import Mgr from "@/services/SecurityServices";

export default {
  name: 'DefaultLayout',
  components: {
    AppFooter,
    AppHeader,
    AppSidebar,
    CContainer,
  },
  data() {
    return {
      signedIn: false
    }
  },
  setup() {
    const mgr = new Mgr();
    return {
      mgr
    }
  },
  async created() {
    await this.mgr.getProfile();
    this.signedIn = true;
  }
}
</script>
