import axios from 'axios';
import {
  PersonsApi, SitesApi, SecurityApi, MovementApi, CertsApi, DevicesApi
} from "@/oas3-ts";
import Mgr from "@/services/SecurityServices";
import swal from "sweetalert2";

export const axiosInstance = axios.create();

// Set default options for axios instance
axiosInstance.defaults.headers.common['x-client'] = 'WebFrontEndV1'
axiosInstance.defaults.headers.common['x-tz-offset'] = (new Date()).getTimezoneOffset() / -60.0 // Timezone offset of browser from UTC
axiosInstance.defaults.headers.common['x-tz-iana'] = Intl.DateTimeFormat().resolvedOptions().timeZone
// Instances
const mgr = new Mgr();

// Request interceptor for API calls
axiosInstance.interceptors.request.use(
  async config => {
    const token = await mgr.getAccessToken();
    config.headers = {
      ...config.headers,
      'Authorization': `Bearer ${token}`
    }
    return config;
  },
  error => {
    Promise.reject(error)
  });

// Response interceptor for API calls
axiosInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 403 && !originalRequest._retry) {
    originalRequest._retry = true;
    await mgr.renewToken();
    const access_token = await mgr.getAccessToken();
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
    return axiosInstance(originalRequest);
  }
  // Intercept 400 errors generated by validator
  else if (error.response.status === 400 && error.response.data && error.response.data.msg) {
    let message = error.response.data.msg + "<br/>Please try again";
    await swal.fire({
      html: message,
      icon: 'error',
    });
  }
  return Promise.reject(error);
});

// configuration, base path, axios instance
const commonParams = [undefined, process.env.VUE_APP_API_URL, axiosInstance];

export default class ApiServices {
  Persons = new PersonsApi(...commonParams);
  Sites = new SitesApi(...commonParams);
  Securities = new SecurityApi(...commonParams);
  Movements = new MovementApi(...commonParams);
  Certificates = new CertsApi(...commonParams);
  Devices = new DevicesApi(...commonParams);
}
