import {
  JsonHubProtocol,
  HubConnectionState,
  HubConnectionBuilder,
  LogLevel, HttpTransportType
} from '@microsoft/signalr';
import Mgr from "@/services/SecurityServices";

// Security Service Manager
const mgr = new Mgr();

export default {
  install: (app) => {
    const connection = new HubConnectionBuilder()
      .withUrl('https://steelally.oneautomation.sg/wss', {
        accessTokenFactory: async () => await mgr.getAccessToken(),
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
          return (Math.random() * 10000) + (retryContext.previousRetryCount * 1000);
        }
      })
      .withHubProtocol(new JsonHubProtocol())
      .configureLogging(LogLevel.Information)
      .build();

    connection.serverTimeoutInMilliseconds = 30000;

    // re-establish the connection if connection dropped
    connection.onclose(error => {
      console.assert(connection.state === HubConnectionState.Disconnected);
      console.log('Connection closed due to error. Try refreshing this page to restart the connection', error);
    });

    connection.onreconnecting(error => {
      console.assert(connection.state === HubConnectionState.Reconnecting);
      console.log('Connection lost due to error. Reconnecting.', error);
    });

    connection.onreconnected(connectionId => {
      console.assert(connection.state === HubConnectionState.Connected);
      console.log('Connection reestablished. Connected with connectionId', connectionId);
    });

    const startWss = async () => {
      try {
        await connection.start();
        console.assert(connection.state === HubConnectionState.Connected);
        console.log('Websocket Established');
      } catch (err) {
        console.assert(connection.state === HubConnectionState.Disconnected);
        console.error('Websocket Error: ', err);
        setTimeout(() => startWss(connection), 5000);
      }
    };

    // Events
    connection.on("onRecognition", (payload) => {
      app.config.globalProperties.emitter.emit("on-recognition", payload);
      console.debug("onRecognition", payload);
    });

    // Start Websocket connection
    startWss().then(() => {
      console.debug('Websocket Ready')
    });
  }
}
