<template>
  <CDropdown>
    <CDropdownToggle
      placement="bottom-end"
      style="box-shadow: none"
      class="py-0"
      :caret="true"
    >
      <span class="text-muted">{{ userName }} <CIcon icon="cil-user"/></span>
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownItem @click="gotoProfile">
        <CIcon icon="cil-key-alt"/>
        Change Password
      </CDropdownItem>
      <CDropdownItem @click="mgr.signOut()">
        <CIcon icon="cil-lock-locked"/>
        Logout
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>
<script>
  import Mgr from "@/services/SecurityServices";

  export default {
    name: 'AppHeaderDropdownAccnt',
    async mounted() {
      const profile = await this.mgr.getProfile();
      this.userName = profile["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
    },
    data() {
      return {
        userName: ""
      }
    },
    setup() {
      const mgr = new Mgr();
      return {
        mgr
      }
    },
    methods: {
      gotoProfile() {
        this.mgr.changePassword();
      }
    }
  }
</script>
<style>

</style>
