import {createApp} from 'vue'
import {createPinia} from 'pinia'
import App from './App.vue'
import router from './router'

import CoreuiVue from '@coreui/vue-pro'
import CIcon from '@coreui/icons-vue'
import {iconsSet as icons} from '@/assets/icons'

import 'maz-ui/css/main.css'
import '@vuepic/vue-datepicker/dist/main.css'
import "@fontsource/roboto"
import "@fontsource/roboto/100.css"
import "@fontsource/roboto/300.css"

import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import MazInputPrice from 'maz-ui/components/MazInputPrice'
import MazInputNumber from 'maz-ui/components/MazInputNumber'
import MazRadioButtons from 'maz-ui/components/MazRadioButtons'
import MazInput from "maz-ui/components/MazInput";
import Datepicker from '@vuepic/vue-datepicker';
import MazPicker from 'maz-ui/components/MazPicker'
import MazSelect from "maz-ui/components/MazSelect";

import WebsocketService from "@/services/WebsocketService";

// Syncfusion
import { registerLicense } from '@syncfusion/ej2-base';
registerLicense('Mgo+DSMBaFt+QHFqVkFrXVNbdV5dVGpAd0N3RGlcdlR1fUUmHVdTRHRcQl5gSX5XdEBnWX1ZcXw=;Mgo+DSMBPh8sVXJ1S0d+X1ZPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSX1QdkVnW3pacHdURmg=;ORg4AjUWIQA/Gnt2VFhhQlJDfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5QdUVjXX1bdHRWRGZU;MTU1NDI0NUAzMjMxMmUzMTJlMzMzN1lEZmxCZnJwUDNuL0F1aVFoNkwxNjcxWjA3M1JlRGcvQ3hsT1FWSjVMVDQ9;MTU1NDI0NkAzMjMxMmUzMTJlMzMzN2pUVGNsLzViR0VrcUxJMFE3bTRqVnVqaTFBcnhsbXhISDZhOTF2UjdFSWc9;NRAiBiAaIQQuGjN/V0d+XU9HcVRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TdUZjWXtcdXBVQmNUWQ==;MTU1NDI0OEAzMjMxMmUzMTJlMzMzN1pURWRuR1c0S1hoaFlBWS9TUS9sSVAxQTNCejloUklmM1czQ25EaEhvdlU9;MTU1NDI0OUAzMjMxMmUzMTJlMzMzN29UWjQrUld2eDlhWU05V3BXZlVkZEwzMUxWTE1uU1pYVDBOakM0ZU81ek09;Mgo+DSMBMAY9C3t2VFhhQlJDfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5QdUVjXX1bdHRdRWVU;MTU1NDI1MUAzMjMxMmUzMTJlMzMzN1JlWHBCOWdzQ1J0dDB0bjk1eU8vTjBqeDRCRFd5NmJCN29LRE82RzNBWms9;MTU1NDI1MkAzMjMxMmUzMTJlMzMzN1RacTA2RFNwM2pMRll6YWljT0ZqZjI4elJFOXpvYTFCOUVaaWpVYnhjMmc9;MTU1NDI1M0AzMjMxMmUzMTJlMzMzN1pURWRuR1c0S1hoaFlBWS9TUS9sSVAxQTNCejloUklmM1czQ25EaEhvdlU9');

// Mitt Pub/Sub
import mitt from 'mitt';
const emitter = mitt();

// DayJS
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import duration from "dayjs/plugin/duration";
import {DatePickerPlugin, DatePicker, TimePickerPlugin, MaskedDateTime, TimePicker} from "@syncfusion/ej2-vue-calendars";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(localizedFormat);

TimePicker.Inject(MaskedDateTime);
DatePicker.Inject(MaskedDateTime);

const app = createApp(App)
const pinia = createPinia()
app.use(pinia)
app.use(router)
app.use(CoreuiVue)
app.use(WebsocketService)
app.use(TimePickerPlugin)
app.use(DatePickerPlugin)

app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('MazPhoneNumberInput', MazPhoneNumberInput)
app.component('MazInputPrice', MazInputPrice)
app.component('MazInput', MazInput)
app.component('MazRadioButtons', MazRadioButtons)
app.component('MazPicker', MazPicker)
app.component('MazInputNumber', MazInputNumber)
app.component('MazSelect', MazSelect)
app.component('Datepicker', Datepicker)

window.emitter = emitter; // Globally accessible emitter
app.config.globalProperties.emitter = emitter; // Vue accessible emitter
app.config.globalProperties.$dayjs = dayjs;

app.provide('dayjs', dayjs);
app.provide('emitter', emitter);

app.mount('#app')
