<template>
  <CFooter>
    <div>
      <span class="ms-1 text-muted small">&copy; {{ new Date().getFullYear() }} SecureAX Pte. Ltd. All Rights Reserved</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
  components: {
  }
}
</script>
