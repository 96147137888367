/* tslint:disable */
/* eslint-disable */
/**
 * Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, BaseAPI } from './base';
/**
 *
 * @export
 * @enum {string}
 */
export const AccessLevel = {
    None: 'None',
    Supervisor: 'Supervisor',
    ProjectManager: 'ProjectManager',
    HrFinance: 'HRFinance',
    Admin: 'Admin'
};
/**
 *
 * @export
 * @enum {string}
 */
export const BiometryType = {
    AppFacial: 'APP_FACIAL',
    FrtFingerprint: 'FRT_FINGERPRINT',
    FrtFacial: 'FRT_FACIAL',
    FrtCard: 'FRT_CARD',
    FrtMultifactor: 'FRT_MULTIFACTOR',
    Automatic: 'AUTOMATIC',
    ManualEntry: 'MANUAL_ENTRY'
};
/**
 *
 * @export
 * @enum {string}
 */
export const Dimension = {
    P: 'P',
    Curve: 'Curve',
    A: 'A',
    Collapse: 'Collapse',
    Dontcare: 'Dontcare',
    True: 'True',
    Unknown: 'Unknown'
};
/**
 *
 * @export
 * @enum {string}
 */
export const Gender = {
    Male: 'Male',
    Female: 'Female',
    Others: 'Others'
};
/**
 *
 * @export
 * @enum {string}
 */
export const IdType = {
    Nric: 'NRIC',
    Workpass: 'Workpass',
    Workpermit: 'Workpermit',
    Passport: 'Passport',
    Others: 'Others'
};
/**
 *
 * @export
 * @enum {string}
 */
export const OgcGeometryType = {
    Point: 'Point',
    LineString: 'LineString',
    Polygon: 'Polygon',
    MultiPoint: 'MultiPoint',
    MultiLineString: 'MultiLineString',
    MultiPolygon: 'MultiPolygon',
    GeometryCollection: 'GeometryCollection',
    CircularString: 'CircularString',
    CompoundCurve: 'CompoundCurve',
    CurvePolygon: 'CurvePolygon',
    MultiCurve: 'MultiCurve',
    MultiSurface: 'MultiSurface',
    Curve: 'Curve',
    Surface: 'Surface',
    PolyhedralSurface: 'PolyhedralSurface',
    Tin: 'TIN'
};
/**
 *
 * @export
 * @enum {string}
 */
export const Ordinates = {
    None: 'None',
    X: 'X',
    Y: 'Y',
    Xy: 'XY',
    Spatial3: 'Spatial3',
    Xyz: 'XYZ',
    Spatial4: 'Spatial4',
    Spatial5: 'Spatial5',
    Spatial6: 'Spatial6',
    Spatial7: 'Spatial7',
    Spatial8: 'Spatial8',
    Spatial9: 'Spatial9',
    Spatial10: 'Spatial10',
    Spatial11: 'Spatial11',
    Spatial12: 'Spatial12',
    Spatial13: 'Spatial13',
    Spatial14: 'Spatial14',
    Spatial15: 'Spatial15',
    Spatial16: 'Spatial16',
    AllSpatialOrdinates: 'AllSpatialOrdinates',
    Measure1: 'Measure1',
    Xym: 'XYM',
    Xyzm: 'XYZM',
    Measure2: 'Measure2',
    Measure3: 'Measure3',
    Measure4: 'Measure4',
    Measure5: 'Measure5',
    Measure6: 'Measure6',
    Measure7: 'Measure7',
    Measure8: 'Measure8',
    Measure9: 'Measure9',
    Measure10: 'Measure10',
    Measure11: 'Measure11',
    Measure12: 'Measure12',
    Measure13: 'Measure13',
    Measure14: 'Measure14',
    Measure15: 'Measure15',
    Measure16: 'Measure16',
    AllMeasureOrdinates: 'AllMeasureOrdinates',
    AllOrdinates: 'AllOrdinates'
};
/**
 *
 * @export
 * @enum {string}
 */
export const PrecisionModels = {
    Floating: 'Floating',
    FloatingSingle: 'FloatingSingle',
    Fixed: 'Fixed'
};
/**
 * CertsApi - axios parameter creator
 * @export
 */
export const CertsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CertsGet: async (options = {}) => {
            const localVarPath = `/api/v1/Certs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CertsIdDelete: async (id, options = {}) => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1CertsIdDelete', 'id', id);
            const localVarPath = `/api/v1/Certs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Int32StringKeyValuePair} [int32StringKeyValuePair]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CertsPost: async (int32StringKeyValuePair, options = {}) => {
            const localVarPath = `/api/v1/Certs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(int32StringKeyValuePair, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Int32StringKeyValuePair} [int32StringKeyValuePair]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CertsPut: async (int32StringKeyValuePair, options = {}) => {
            const localVarPath = `/api/v1/Certs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(int32StringKeyValuePair, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * CertsApi - functional programming interface
 * @export
 */
export const CertsApiFp = function (configuration) {
    const localVarAxiosParamCreator = CertsApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CertsGet(options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CertsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CertsIdDelete(id, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CertsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {Int32StringKeyValuePair} [int32StringKeyValuePair]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CertsPost(int32StringKeyValuePair, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CertsPost(int32StringKeyValuePair, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {Int32StringKeyValuePair} [int32StringKeyValuePair]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CertsPut(int32StringKeyValuePair, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CertsPut(int32StringKeyValuePair, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};
/**
 * CertsApi - factory interface
 * @export
 */
export const CertsApiFactory = function (configuration, basePath, axios) {
    const localVarFp = CertsApiFp(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CertsGet(options) {
            return localVarFp.apiV1CertsGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CertsIdDelete(id, options) {
            return localVarFp.apiV1CertsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Int32StringKeyValuePair} [int32StringKeyValuePair]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CertsPost(int32StringKeyValuePair, options) {
            return localVarFp.apiV1CertsPost(int32StringKeyValuePair, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Int32StringKeyValuePair} [int32StringKeyValuePair]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CertsPut(int32StringKeyValuePair, options) {
            return localVarFp.apiV1CertsPut(int32StringKeyValuePair, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * CertsApi - object-oriented interface
 * @export
 * @class CertsApi
 * @extends {BaseAPI}
 */
export class CertsApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertsApi
     */
    apiV1CertsGet(options) {
        return CertsApiFp(this.configuration).apiV1CertsGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertsApi
     */
    apiV1CertsIdDelete(id, options) {
        return CertsApiFp(this.configuration).apiV1CertsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {Int32StringKeyValuePair} [int32StringKeyValuePair]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertsApi
     */
    apiV1CertsPost(int32StringKeyValuePair, options) {
        return CertsApiFp(this.configuration).apiV1CertsPost(int32StringKeyValuePair, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {Int32StringKeyValuePair} [int32StringKeyValuePair]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertsApi
     */
    apiV1CertsPut(int32StringKeyValuePair, options) {
        return CertsApiFp(this.configuration).apiV1CertsPut(int32StringKeyValuePair, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * DevicesApi - axios parameter creator
 * @export
 */
export const DevicesApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DevicesFrtStatusGet: async (options = {}) => {
            const localVarPath = `/api/v1/Devices/frtStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [deviceID]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DevicesFsyncPost: async (deviceID, options = {}) => {
            const localVarPath = `/api/v1/Devices/fsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            if (deviceID !== undefined) {
                localVarQueryParameter['DeviceID'] = deviceID;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {DevLocOvrDTO} [devLocOvrDTO]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DevicesLocOvrPost: async (devLocOvrDTO, options = {}) => {
            const localVarPath = `/api/v1/Devices/locOvr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(devLocOvrDTO, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {boolean} [terminalsOnly]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DevicesNamesGet: async (terminalsOnly, options = {}) => {
            const localVarPath = `/api/v1/Devices/names`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            if (terminalsOnly !== undefined) {
                localVarQueryParameter['TerminalsOnly'] = terminalsOnly;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {boolean} [showArchived]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DevicesRoutersGet: async (showArchived, options = {}) => {
            const localVarPath = `/api/v1/Devices/routers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            if (showArchived !== undefined) {
                localVarQueryParameter['ShowArchived'] = showArchived;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [deviceId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DevicesSyncUnblockPost: async (deviceId, options = {}) => {
            const localVarPath = `/api/v1/Devices/syncUnblock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            if (deviceId !== undefined) {
                localVarQueryParameter['DeviceId'] = deviceId;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * DevicesApi - functional programming interface
 * @export
 */
export const DevicesApiFp = function (configuration) {
    const localVarAxiosParamCreator = DevicesApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DevicesFrtStatusGet(options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DevicesFrtStatusGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} [deviceID]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DevicesFsyncPost(deviceID, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DevicesFsyncPost(deviceID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {DevLocOvrDTO} [devLocOvrDTO]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DevicesLocOvrPost(devLocOvrDTO, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DevicesLocOvrPost(devLocOvrDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {boolean} [terminalsOnly]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DevicesNamesGet(terminalsOnly, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DevicesNamesGet(terminalsOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {boolean} [showArchived]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DevicesRoutersGet(showArchived, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DevicesRoutersGet(showArchived, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} [deviceId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DevicesSyncUnblockPost(deviceId, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DevicesSyncUnblockPost(deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};
/**
 * DevicesApi - factory interface
 * @export
 */
export const DevicesApiFactory = function (configuration, basePath, axios) {
    const localVarFp = DevicesApiFp(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DevicesFrtStatusGet(options) {
            return localVarFp.apiV1DevicesFrtStatusGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} [deviceID]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DevicesFsyncPost(deviceID, options) {
            return localVarFp.apiV1DevicesFsyncPost(deviceID, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {DevLocOvrDTO} [devLocOvrDTO]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DevicesLocOvrPost(devLocOvrDTO, options) {
            return localVarFp.apiV1DevicesLocOvrPost(devLocOvrDTO, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {boolean} [terminalsOnly]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DevicesNamesGet(terminalsOnly, options) {
            return localVarFp.apiV1DevicesNamesGet(terminalsOnly, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {boolean} [showArchived]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DevicesRoutersGet(showArchived, options) {
            return localVarFp.apiV1DevicesRoutersGet(showArchived, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} [deviceId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DevicesSyncUnblockPost(deviceId, options) {
            return localVarFp.apiV1DevicesSyncUnblockPost(deviceId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * DevicesApi - object-oriented interface
 * @export
 * @class DevicesApi
 * @extends {BaseAPI}
 */
export class DevicesApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    apiV1DevicesFrtStatusGet(options) {
        return DevicesApiFp(this.configuration).apiV1DevicesFrtStatusGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} [deviceID]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    apiV1DevicesFsyncPost(deviceID, options) {
        return DevicesApiFp(this.configuration).apiV1DevicesFsyncPost(deviceID, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {DevLocOvrDTO} [devLocOvrDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    apiV1DevicesLocOvrPost(devLocOvrDTO, options) {
        return DevicesApiFp(this.configuration).apiV1DevicesLocOvrPost(devLocOvrDTO, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {boolean} [terminalsOnly]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    apiV1DevicesNamesGet(terminalsOnly, options) {
        return DevicesApiFp(this.configuration).apiV1DevicesNamesGet(terminalsOnly, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {boolean} [showArchived]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    apiV1DevicesRoutersGet(showArchived, options) {
        return DevicesApiFp(this.configuration).apiV1DevicesRoutersGet(showArchived, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} [deviceId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    apiV1DevicesSyncUnblockPost(deviceId, options) {
        return DevicesApiFp(this.configuration).apiV1DevicesSyncUnblockPost(deviceId, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * HolidayApi - axios parameter creator
 * @export
 */
export const HolidayApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HolidayGet: async (options = {}) => {
            const localVarPath = `/api/v1/Holiday`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HolidayIdDelete: async (id, options = {}) => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1HolidayIdDelete', 'id', id);
            const localVarPath = `/api/v1/Holiday/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {HolidayDTO} [holidayDTO]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HolidayPost: async (holidayDTO, options = {}) => {
            const localVarPath = `/api/v1/Holiday`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(holidayDTO, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {HolidayDTO} [holidayDTO]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HolidayPut: async (holidayDTO, options = {}) => {
            const localVarPath = `/api/v1/Holiday`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(holidayDTO, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * HolidayApi - functional programming interface
 * @export
 */
export const HolidayApiFp = function (configuration) {
    const localVarAxiosParamCreator = HolidayApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1HolidayGet(options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1HolidayGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1HolidayIdDelete(id, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1HolidayIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {HolidayDTO} [holidayDTO]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1HolidayPost(holidayDTO, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1HolidayPost(holidayDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {HolidayDTO} [holidayDTO]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1HolidayPut(holidayDTO, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1HolidayPut(holidayDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};
/**
 * HolidayApi - factory interface
 * @export
 */
export const HolidayApiFactory = function (configuration, basePath, axios) {
    const localVarFp = HolidayApiFp(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HolidayGet(options) {
            return localVarFp.apiV1HolidayGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HolidayIdDelete(id, options) {
            return localVarFp.apiV1HolidayIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {HolidayDTO} [holidayDTO]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HolidayPost(holidayDTO, options) {
            return localVarFp.apiV1HolidayPost(holidayDTO, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {HolidayDTO} [holidayDTO]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HolidayPut(holidayDTO, options) {
            return localVarFp.apiV1HolidayPut(holidayDTO, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * HolidayApi - object-oriented interface
 * @export
 * @class HolidayApi
 * @extends {BaseAPI}
 */
export class HolidayApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolidayApi
     */
    apiV1HolidayGet(options) {
        return HolidayApiFp(this.configuration).apiV1HolidayGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolidayApi
     */
    apiV1HolidayIdDelete(id, options) {
        return HolidayApiFp(this.configuration).apiV1HolidayIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {HolidayDTO} [holidayDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolidayApi
     */
    apiV1HolidayPost(holidayDTO, options) {
        return HolidayApiFp(this.configuration).apiV1HolidayPost(holidayDTO, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {HolidayDTO} [holidayDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolidayApi
     */
    apiV1HolidayPut(holidayDTO, options) {
        return HolidayApiFp(this.configuration).apiV1HolidayPut(holidayDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * MovementApi - axios parameter creator
 * @export
 */
export const MovementApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {MovQuery} [movQuery]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MovementConcretePost: async (movQuery, options = {}) => {
            const localVarPath = `/api/v1/Movement/concrete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(movQuery, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {MovQuery} [movQuery]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MovementHrExportPost: async (movQuery, options = {}) => {
            const localVarPath = `/api/v1/Movement/hr_export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(movQuery, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [siteId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MovementInsydeGet: async (siteId, options = {}) => {
            const localVarPath = `/api/v1/Movement/insyde`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            if (siteId !== undefined) {
                localVarQueryParameter['SiteId'] = siteId;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MovementLastSeenGet: async (options = {}) => {
            const localVarPath = `/api/v1/Movement/lastSeen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {MovQuery} [movQuery]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MovementLatestPost: async (movQuery, options = {}) => {
            const localVarPath = `/api/v1/Movement/latest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(movQuery, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {MovEntryManual} [movEntryManual]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MovementManualPost: async (movEntryManual, options = {}) => {
            const localVarPath = `/api/v1/Movement/manual`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(movEntryManual, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {MovReqModifyConcrete} [movReqModifyConcrete]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MovementModifyPut: async (movReqModifyConcrete, options = {}) => {
            const localVarPath = `/api/v1/Movement/modify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(movReqModifyConcrete, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {MovReqUpdate} [movReqUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MovementUpdatePut: async (movReqUpdate, options = {}) => {
            const localVarPath = `/api/v1/Movement/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(movReqUpdate, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * MovementApi - functional programming interface
 * @export
 */
export const MovementApiFp = function (configuration) {
    const localVarAxiosParamCreator = MovementApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {MovQuery} [movQuery]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MovementConcretePost(movQuery, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MovementConcretePost(movQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {MovQuery} [movQuery]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MovementHrExportPost(movQuery, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MovementHrExportPost(movQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} [siteId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MovementInsydeGet(siteId, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MovementInsydeGet(siteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MovementLastSeenGet(options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MovementLastSeenGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {MovQuery} [movQuery]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MovementLatestPost(movQuery, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MovementLatestPost(movQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {MovEntryManual} [movEntryManual]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MovementManualPost(movEntryManual, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MovementManualPost(movEntryManual, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {MovReqModifyConcrete} [movReqModifyConcrete]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MovementModifyPut(movReqModifyConcrete, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MovementModifyPut(movReqModifyConcrete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {MovReqUpdate} [movReqUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MovementUpdatePut(movReqUpdate, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MovementUpdatePut(movReqUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};
/**
 * MovementApi - factory interface
 * @export
 */
export const MovementApiFactory = function (configuration, basePath, axios) {
    const localVarFp = MovementApiFp(configuration);
    return {
        /**
         *
         * @param {MovQuery} [movQuery]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MovementConcretePost(movQuery, options) {
            return localVarFp.apiV1MovementConcretePost(movQuery, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {MovQuery} [movQuery]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MovementHrExportPost(movQuery, options) {
            return localVarFp.apiV1MovementHrExportPost(movQuery, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} [siteId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MovementInsydeGet(siteId, options) {
            return localVarFp.apiV1MovementInsydeGet(siteId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MovementLastSeenGet(options) {
            return localVarFp.apiV1MovementLastSeenGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {MovQuery} [movQuery]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MovementLatestPost(movQuery, options) {
            return localVarFp.apiV1MovementLatestPost(movQuery, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {MovEntryManual} [movEntryManual]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MovementManualPost(movEntryManual, options) {
            return localVarFp.apiV1MovementManualPost(movEntryManual, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {MovReqModifyConcrete} [movReqModifyConcrete]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MovementModifyPut(movReqModifyConcrete, options) {
            return localVarFp.apiV1MovementModifyPut(movReqModifyConcrete, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {MovReqUpdate} [movReqUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MovementUpdatePut(movReqUpdate, options) {
            return localVarFp.apiV1MovementUpdatePut(movReqUpdate, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * MovementApi - object-oriented interface
 * @export
 * @class MovementApi
 * @extends {BaseAPI}
 */
export class MovementApi extends BaseAPI {
    /**
     *
     * @param {MovQuery} [movQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovementApi
     */
    apiV1MovementConcretePost(movQuery, options) {
        return MovementApiFp(this.configuration).apiV1MovementConcretePost(movQuery, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {MovQuery} [movQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovementApi
     */
    apiV1MovementHrExportPost(movQuery, options) {
        return MovementApiFp(this.configuration).apiV1MovementHrExportPost(movQuery, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} [siteId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovementApi
     */
    apiV1MovementInsydeGet(siteId, options) {
        return MovementApiFp(this.configuration).apiV1MovementInsydeGet(siteId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovementApi
     */
    apiV1MovementLastSeenGet(options) {
        return MovementApiFp(this.configuration).apiV1MovementLastSeenGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {MovQuery} [movQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovementApi
     */
    apiV1MovementLatestPost(movQuery, options) {
        return MovementApiFp(this.configuration).apiV1MovementLatestPost(movQuery, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {MovEntryManual} [movEntryManual]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovementApi
     */
    apiV1MovementManualPost(movEntryManual, options) {
        return MovementApiFp(this.configuration).apiV1MovementManualPost(movEntryManual, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {MovReqModifyConcrete} [movReqModifyConcrete]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovementApi
     */
    apiV1MovementModifyPut(movReqModifyConcrete, options) {
        return MovementApiFp(this.configuration).apiV1MovementModifyPut(movReqModifyConcrete, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {MovReqUpdate} [movReqUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovementApi
     */
    apiV1MovementUpdatePut(movReqUpdate, options) {
        return MovementApiFp(this.configuration).apiV1MovementUpdatePut(movReqUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * PersonsApi - axios parameter creator
 * @export
 */
export const PersonsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {EnrollModel} [enrollModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PersonsCapturePost: async (enrollModel, options = {}) => {
            const localVarPath = `/api/v1/Persons/capture`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(enrollModel, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {boolean} [showArchived]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PersonsGet: async (showArchived, options = {}) => {
            const localVarPath = `/api/v1/Persons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            if (showArchived !== undefined) {
                localVarQueryParameter['ShowArchived'] = showArchived;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PersonsIdBiometriesGet: async (id, options = {}) => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PersonsIdBiometriesGet', 'id', id);
            const localVarPath = `/api/v1/Persons/{id}/biometries`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PersonsIdDelete: async (id, options = {}) => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PersonsIdDelete', 'id', id);
            const localVarPath = `/api/v1/Persons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PersonsIdGet: async (id, options = {}) => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PersonsIdGet', 'id', id);
            const localVarPath = `/api/v1/Persons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {EnrollModel} [enrollModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PersonsImagePost: async (enrollModel, options = {}) => {
            const localVarPath = `/api/v1/Persons/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(enrollModel, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {PersonDto} [personDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PersonsPost: async (personDto, options = {}) => {
            const localVarPath = `/api/v1/Persons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(personDto, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {PersonDto} [personDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PersonsPut: async (personDto, options = {}) => {
            const localVarPath = `/api/v1/Persons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(personDto, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * PersonsApi - functional programming interface
 * @export
 */
export const PersonsApiFp = function (configuration) {
    const localVarAxiosParamCreator = PersonsApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {EnrollModel} [enrollModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PersonsCapturePost(enrollModel, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PersonsCapturePost(enrollModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {boolean} [showArchived]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PersonsGet(showArchived, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PersonsGet(showArchived, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PersonsIdBiometriesGet(id, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PersonsIdBiometriesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PersonsIdDelete(id, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PersonsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PersonsIdGet(id, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PersonsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {EnrollModel} [enrollModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PersonsImagePost(enrollModel, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PersonsImagePost(enrollModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {PersonDto} [personDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PersonsPost(personDto, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PersonsPost(personDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {PersonDto} [personDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PersonsPut(personDto, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PersonsPut(personDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};
/**
 * PersonsApi - factory interface
 * @export
 */
export const PersonsApiFactory = function (configuration, basePath, axios) {
    const localVarFp = PersonsApiFp(configuration);
    return {
        /**
         *
         * @param {EnrollModel} [enrollModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PersonsCapturePost(enrollModel, options) {
            return localVarFp.apiV1PersonsCapturePost(enrollModel, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {boolean} [showArchived]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PersonsGet(showArchived, options) {
            return localVarFp.apiV1PersonsGet(showArchived, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PersonsIdBiometriesGet(id, options) {
            return localVarFp.apiV1PersonsIdBiometriesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PersonsIdDelete(id, options) {
            return localVarFp.apiV1PersonsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PersonsIdGet(id, options) {
            return localVarFp.apiV1PersonsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {EnrollModel} [enrollModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PersonsImagePost(enrollModel, options) {
            return localVarFp.apiV1PersonsImagePost(enrollModel, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PersonDto} [personDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PersonsPost(personDto, options) {
            return localVarFp.apiV1PersonsPost(personDto, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PersonDto} [personDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PersonsPut(personDto, options) {
            return localVarFp.apiV1PersonsPut(personDto, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * PersonsApi - object-oriented interface
 * @export
 * @class PersonsApi
 * @extends {BaseAPI}
 */
export class PersonsApi extends BaseAPI {
    /**
     *
     * @param {EnrollModel} [enrollModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonsApi
     */
    apiV1PersonsCapturePost(enrollModel, options) {
        return PersonsApiFp(this.configuration).apiV1PersonsCapturePost(enrollModel, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {boolean} [showArchived]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonsApi
     */
    apiV1PersonsGet(showArchived, options) {
        return PersonsApiFp(this.configuration).apiV1PersonsGet(showArchived, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonsApi
     */
    apiV1PersonsIdBiometriesGet(id, options) {
        return PersonsApiFp(this.configuration).apiV1PersonsIdBiometriesGet(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonsApi
     */
    apiV1PersonsIdDelete(id, options) {
        return PersonsApiFp(this.configuration).apiV1PersonsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonsApi
     */
    apiV1PersonsIdGet(id, options) {
        return PersonsApiFp(this.configuration).apiV1PersonsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {EnrollModel} [enrollModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonsApi
     */
    apiV1PersonsImagePost(enrollModel, options) {
        return PersonsApiFp(this.configuration).apiV1PersonsImagePost(enrollModel, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PersonDto} [personDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonsApi
     */
    apiV1PersonsPost(personDto, options) {
        return PersonsApiFp(this.configuration).apiV1PersonsPost(personDto, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PersonDto} [personDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonsApi
     */
    apiV1PersonsPut(personDto, options) {
        return PersonsApiFp(this.configuration).apiV1PersonsPut(personDto, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * SecurityApi - axios parameter creator
 * @export
 */
export const SecurityApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityDodeseedPost: async (options = {}) => {
            const localVarPath = `/api/v1/Security/dodeseed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityInstGet: async (options = {}) => {
            const localVarPath = `/api/v1/Security/inst`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {MasterPasswordRequest} [masterPasswordRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityMasterPwdPut: async (masterPasswordRequest, options = {}) => {
            const localVarPath = `/api/v1/Security/masterPwd`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(masterPasswordRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {boolean} [showArchived]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityUserGet: async (showArchived, options = {}) => {
            const localVarPath = `/api/v1/Security/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            if (showArchived !== undefined) {
                localVarQueryParameter['ShowArchived'] = showArchived;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityUserIdDelete: async (id, options = {}) => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1SecurityUserIdDelete', 'id', id);
            const localVarPath = `/api/v1/Security/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {AppUserViewModel} [appUserViewModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityUserPost: async (appUserViewModel, options = {}) => {
            const localVarPath = `/api/v1/Security/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(appUserViewModel, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {AppUserViewModel} [appUserViewModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityUserPut: async (appUserViewModel, options = {}) => {
            const localVarPath = `/api/v1/Security/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(appUserViewModel, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * SecurityApi - functional programming interface
 * @export
 */
export const SecurityApiFp = function (configuration) {
    const localVarAxiosParamCreator = SecurityApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SecurityDodeseedPost(options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SecurityDodeseedPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SecurityInstGet(options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SecurityInstGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {MasterPasswordRequest} [masterPasswordRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SecurityMasterPwdPut(masterPasswordRequest, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SecurityMasterPwdPut(masterPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {boolean} [showArchived]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SecurityUserGet(showArchived, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SecurityUserGet(showArchived, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SecurityUserIdDelete(id, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SecurityUserIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {AppUserViewModel} [appUserViewModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SecurityUserPost(appUserViewModel, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SecurityUserPost(appUserViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {AppUserViewModel} [appUserViewModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SecurityUserPut(appUserViewModel, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SecurityUserPut(appUserViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};
/**
 * SecurityApi - factory interface
 * @export
 */
export const SecurityApiFactory = function (configuration, basePath, axios) {
    const localVarFp = SecurityApiFp(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityDodeseedPost(options) {
            return localVarFp.apiV1SecurityDodeseedPost(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityInstGet(options) {
            return localVarFp.apiV1SecurityInstGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {MasterPasswordRequest} [masterPasswordRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityMasterPwdPut(masterPasswordRequest, options) {
            return localVarFp.apiV1SecurityMasterPwdPut(masterPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {boolean} [showArchived]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityUserGet(showArchived, options) {
            return localVarFp.apiV1SecurityUserGet(showArchived, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityUserIdDelete(id, options) {
            return localVarFp.apiV1SecurityUserIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {AppUserViewModel} [appUserViewModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityUserPost(appUserViewModel, options) {
            return localVarFp.apiV1SecurityUserPost(appUserViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {AppUserViewModel} [appUserViewModel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityUserPut(appUserViewModel, options) {
            return localVarFp.apiV1SecurityUserPut(appUserViewModel, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * SecurityApi - object-oriented interface
 * @export
 * @class SecurityApi
 * @extends {BaseAPI}
 */
export class SecurityApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    apiV1SecurityDodeseedPost(options) {
        return SecurityApiFp(this.configuration).apiV1SecurityDodeseedPost(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    apiV1SecurityInstGet(options) {
        return SecurityApiFp(this.configuration).apiV1SecurityInstGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {MasterPasswordRequest} [masterPasswordRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    apiV1SecurityMasterPwdPut(masterPasswordRequest, options) {
        return SecurityApiFp(this.configuration).apiV1SecurityMasterPwdPut(masterPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {boolean} [showArchived]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    apiV1SecurityUserGet(showArchived, options) {
        return SecurityApiFp(this.configuration).apiV1SecurityUserGet(showArchived, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    apiV1SecurityUserIdDelete(id, options) {
        return SecurityApiFp(this.configuration).apiV1SecurityUserIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {AppUserViewModel} [appUserViewModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    apiV1SecurityUserPost(appUserViewModel, options) {
        return SecurityApiFp(this.configuration).apiV1SecurityUserPost(appUserViewModel, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {AppUserViewModel} [appUserViewModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    apiV1SecurityUserPut(appUserViewModel, options) {
        return SecurityApiFp(this.configuration).apiV1SecurityUserPut(appUserViewModel, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * SitesApi - axios parameter creator
 * @export
 */
export const SitesApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SitesCalcsGet: async (options = {}) => {
            const localVarPath = `/api/v1/Sites/calcs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {boolean} [showArchived]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SitesGet: async (showArchived, options = {}) => {
            const localVarPath = `/api/v1/Sites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            if (showArchived !== undefined) {
                localVarQueryParameter['ShowArchived'] = showArchived;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SitesIdDelete: async (id, options = {}) => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1SitesIdDelete', 'id', id);
            const localVarPath = `/api/v1/Sites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SitesIdGet: async (id, options = {}) => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1SitesIdGet', 'id', id);
            const localVarPath = `/api/v1/Sites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {JobsiteDTO} [jobsiteDTO]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SitesPost: async (jobsiteDTO, options = {}) => {
            const localVarPath = `/api/v1/Sites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(jobsiteDTO, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {JobsiteDTO} [jobsiteDTO]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SitesPut: async (jobsiteDTO, options = {}) => {
            const localVarPath = `/api/v1/Sites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(jobsiteDTO, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {QueryWhereAmI} [queryWhereAmI]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SitesWhereamiPost: async (queryWhereAmI, options = {}) => {
            const localVarPath = `/api/v1/Sites/whereami`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oidc required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(queryWhereAmI, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * SitesApi - functional programming interface
 * @export
 */
export const SitesApiFp = function (configuration) {
    const localVarAxiosParamCreator = SitesApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SitesCalcsGet(options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SitesCalcsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {boolean} [showArchived]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SitesGet(showArchived, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SitesGet(showArchived, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SitesIdDelete(id, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SitesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SitesIdGet(id, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SitesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {JobsiteDTO} [jobsiteDTO]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SitesPost(jobsiteDTO, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SitesPost(jobsiteDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {JobsiteDTO} [jobsiteDTO]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SitesPut(jobsiteDTO, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SitesPut(jobsiteDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {QueryWhereAmI} [queryWhereAmI]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SitesWhereamiPost(queryWhereAmI, options) {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SitesWhereamiPost(queryWhereAmI, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};
/**
 * SitesApi - factory interface
 * @export
 */
export const SitesApiFactory = function (configuration, basePath, axios) {
    const localVarFp = SitesApiFp(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SitesCalcsGet(options) {
            return localVarFp.apiV1SitesCalcsGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {boolean} [showArchived]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SitesGet(showArchived, options) {
            return localVarFp.apiV1SitesGet(showArchived, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SitesIdDelete(id, options) {
            return localVarFp.apiV1SitesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SitesIdGet(id, options) {
            return localVarFp.apiV1SitesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {JobsiteDTO} [jobsiteDTO]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SitesPost(jobsiteDTO, options) {
            return localVarFp.apiV1SitesPost(jobsiteDTO, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {JobsiteDTO} [jobsiteDTO]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SitesPut(jobsiteDTO, options) {
            return localVarFp.apiV1SitesPut(jobsiteDTO, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {QueryWhereAmI} [queryWhereAmI]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SitesWhereamiPost(queryWhereAmI, options) {
            return localVarFp.apiV1SitesWhereamiPost(queryWhereAmI, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * SitesApi - object-oriented interface
 * @export
 * @class SitesApi
 * @extends {BaseAPI}
 */
export class SitesApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    apiV1SitesCalcsGet(options) {
        return SitesApiFp(this.configuration).apiV1SitesCalcsGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {boolean} [showArchived]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    apiV1SitesGet(showArchived, options) {
        return SitesApiFp(this.configuration).apiV1SitesGet(showArchived, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    apiV1SitesIdDelete(id, options) {
        return SitesApiFp(this.configuration).apiV1SitesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    apiV1SitesIdGet(id, options) {
        return SitesApiFp(this.configuration).apiV1SitesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {JobsiteDTO} [jobsiteDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    apiV1SitesPost(jobsiteDTO, options) {
        return SitesApiFp(this.configuration).apiV1SitesPost(jobsiteDTO, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {JobsiteDTO} [jobsiteDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    apiV1SitesPut(jobsiteDTO, options) {
        return SitesApiFp(this.configuration).apiV1SitesPut(jobsiteDTO, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {QueryWhereAmI} [queryWhereAmI]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    apiV1SitesWhereamiPost(queryWhereAmI, options) {
        return SitesApiFp(this.configuration).apiV1SitesWhereamiPost(queryWhereAmI, options).then((request) => request(this.axios, this.basePath));
    }
}
