import {
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibGoogle,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibCcStripe,
  cibCcPaypal,
  cibCcApplePay,
  cibCcAmex,
  cidQueueAdd,
  cisHistory,
  cisStar,
  cisMediaPause,
  cisMediaPlay,
  cisMediaStop,
  cisCartPlus,
  cilCity,
  cilIdCard,
  cilHourglass,
  cilUpdate,
  cilSwapHorizontal,
  cilIdBadge,
  cilFingerprint,
  cilFace,
  cilCloudUpload,
  cilCameraPlus,
  cisSpreadsheet,
  cilFileExcel,
  cilHandPaper,
  cisAccountLogout,
  cisLocationPinCheck,
} from '@coreui/icons-pro'
import {
  cilSortNumericDown,
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowBottom,
  cilArrowRight,
  cilArrowTop,
  cilAvTimer,
  cilBan,
  cilBasket,
  cilBell,
  cilBookmark,
  cilBold,
  cilBraille,
  cilBuilding,
  cilCalculator,
  cilCalendar,
  cilCash,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCheckCircle,
  cilCode,
  cilCommentSquare,
  cilContact,
  cilCursor,
  cilDinner,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilFile,
  cilGem,
  cilGlobeAlt,
  cilGrid,
  cilGroup,
  cilHome,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInstitution,
  cilItalic,
  cilJustifyCenter,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMediaPlay,
  cilMediaPause,
  cilMediaStop,
  cilMenu,
  cilMonitor,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPencil,
  cilPeople,
  cilPrint,
  cilPuzzle,
  cilRoom,
  cilSave,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilWarning,
  cilXCircle,
  cilStore,
  cilKeyAlt,
  cisInbox,
  cisInboxIn,
  cisInboxOut,
  cilInfo,
  cilArrowCircleLeft,
  cilArrowThickLeft,
  cilArrowThickRight,
  cisKeyAlt,
  cilRouter,
  cilExitToApp,
  cisAlarmOn,
  cisAlarmOff,
  cisHappy,
  cisReport,
  cisSearch,
  cisTransferWithinAStation
} from '@coreui/icons-pro'

export const iconsSet = Object.assign(
  {},
  {
    cisHappy,
    cisReport,
    cisAlarmOn,
    cisAlarmOff,
    cilExitToApp,
    cilAlignCenter,
    cilAlignLeft,
    cilAlignRight,
    cilApplicationsSettings,
    cilArrowBottom,
    cilArrowRight,
    cilArrowTop,
    cilAvTimer,
    cilBan,
    cilBasket,
    cilBell,
    cilBookmark,
    cilBold,
    cilBraille,
    cilBuilding,
    cilCalculator,
    cilCalendar,
    cilCash,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronLeft,
    cilChevronRight,
    cilChevronTop,
    cilCheckCircle,
    cilCode,
    cilCommentSquare,
    cilCursor,
    cilDinner,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeLetter,
    cilEnvelopeOpen,
    cilEuro,
    cilFile,
    cilGem,
    cilGlobeAlt,
    cilGrid,
    cilGroup,
    cilHome,
    cilInbox,
    cilIndentDecrease,
    cilIndentIncrease,
    cilInstitution,
    cilItalic,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilListNumbered,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMediaPlay,
    cilMediaPause,
    cilMediaStop,
    cilMenu,
    cilMoon,
    cilMonitor,
    cilNotes,
    cilOptions,
    cilPaperPlane,
    cilPaperclip,
    cilPencil,
    cilPeople,
    cilPrint,
    cilPuzzle,
    cilRoom,
    cilSave,
    cilSettings,
    cilShare,
    cilShareAll,
    cilShareBoxed,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilSpreadsheet,
    cilStar,
    cilSun,
    cilTags,
    cilTask,
    cilTrash,
    cilUnderline,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilWarning,
    cilXCircle,
    cilStore,
    cilKeyAlt,
    cilContact,
    cidQueueAdd,
    cisHistory,
    cisStar,
    cisMediaPause,
    cisMediaPlay,
    cisMediaStop,
    cisCartPlus,
    cisInbox,
    cisInboxIn,
    cisInboxOut,
    cilInfo,
    cilArrowCircleLeft,
    cilArrowThickLeft,
    cilArrowThickRight,
    cisKeyAlt,
    cilCity,
    cilIdCard,
    cilHourglass,
    cilUpdate,
    cilSwapHorizontal,
    cilIdBadge,
    cisSpreadsheet,
    cilSortNumericDown,
  },
  {
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibGoogle,
    cibStackoverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibCcStripe,
    cibCcPaypal,
    cibCcApplePay,
    cibCcAmex,
    cilFingerprint,
    cilFace,
    cilRouter,
    cilCloudUpload,
    cilCameraPlus,
    cisTransferWithinAStation,
    cisSearch,
    cilFileExcel,
    cilHandPaper,
    cisAccountLogout,
    cisLocationPinCheck,
  },
)
