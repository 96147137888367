import {Log, UserManager, WebStorageStateStore} from "oidc-client-ts"
// import {useGlobalStore} from "@/stores/globalStore";

const settings = {
  authority: "https://steelally-sso.oneautomation.sg",
  client_id: "bacsClient",
  redirect_uri: window.location.origin + "/static/oidc-callback.html",
  silent_redirect_uri: window.location.origin + "/static/silentRenew.html",
  post_logout_redirect_uri: window.location.origin + "/#/pages/401",
  response_type: "code",
  response_mode: "fragment",
  scope: "openid profile bacsApi",
  filterProtocolClaims: true,
  accessTokenExpiringNotificationTimeInSeconds: 120,
  automaticSilentRenew: true,
  loadUserInfo: true,
  userStore: new WebStorageStateStore()
};

Log.setLogger(console);
Log.setLevel(Log.WARN);

const mgr = new UserManager(settings);

/*

mgr.events.addUserLoaded(function () {

});

mgr.events.addUserSignedIn(function (e) {
  console.log("user logged in to the token server", e);
});

mgr.events.addAccessTokenExpiring(function () {
  console.log('AccessToken is expiring');
});

mgr.events.addSilentRenewError(function () {
  console.error('Silent Renew Error： ', arguments);
});



mgr.events.addUserSignedOut(function (e) {
  console.log("user logged out of the token server", e);
});
*/

mgr.events.addAccessTokenExpired(function () {
  console.log('AccessToken Expired： ', arguments);
  mgr.signinRedirect().catch(function (err) {
    console.log(err)
  })
 });

mgr.events.addUserSignedOut(function () {
  console.log('UserSignedOut： ', arguments);
  mgr.signoutRedirect().then(function (resp) {
    console.log('Signed Out', resp);
  }).catch(function (err) {
    console.log(err)
  })
});

export default class SecurityServices {

  // Renew the token manually
  renewToken() {
    let self = this
    return new Promise((resolve, reject) => {
      mgr.signinSilent().then(function (user) {
        if (user == null) {
          self.signIn(null)
        } else {
          return resolve(user)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      });
    })
  }

  // Get the user who is logged in
  getUser() {
    let self = this
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function (user) {
        if (user == null) {
          self.signIn()
          return resolve(null)
        } else {
          return resolve(user)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      });
    })
  }

  // Check if there is any user logged in
  getSignedIn() {
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function (user) {
        if (user == null) {
          return resolve(false)
        } else {
          return resolve(true)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      });
    })
  }

  // Redirect of the current window to the authorization endpoint.
  signIn() {
    mgr.signinRedirect()
      .catch(function (err) {
        console.log(err)
      })
  }

  // Redirect of the current window to the authorization endpoint.
  changePassword() {
    mgr.signinRedirect({
      extraQueryParams: {
        kc_action: "UPDATE_PASSWORD"
      },
    }).catch(function (err) {
      console.log(err)
    })
  }


  // Redirect of the current window to the end session endpoint
  signOut() {
    window.sessionStorage.clear();

    mgr.signoutRedirect().then(function (resp) {
      console.log('signed out', resp);
    }).catch(function (err) {
      console.log(err)
    })
  }

  // Get the profile of the user logged in
  getProfile() {
    let self = this
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function (user) {
        if (user == null) {
          self.signIn()
          return resolve(null)
        } else {
          return resolve(user.profile)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      });
    })
  }

  // Get the token id
  getIdToken() {
    let self = this
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function (user) {
        if (user == null) {
          self.signIn()
          return resolve(null)
        } else {
          return resolve(user.id_token)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      });
    })
  }

  // Get the session state
  getSessionState() {
    let self = this
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function (user) {
        if (user == null) {
          self.signIn()
          return resolve(null)
        } else {
          return resolve(user.session_state)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      });
    })
  }

  // Get the access token of the logged in user
  getAccessToken() {
    let self = this
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function (user) {
        if (user == null) {
          self.signIn()
          return resolve(null)
        } else {
          return resolve(user.access_token)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      });
    })
  }

  // Takes the scopes of the logged in user
  getScopes() {
    let self = this
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function (user) {
        if (user == null) {
          self.signIn()
          return resolve(null)
        } else {
          return resolve(user.scopes)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      });
    })
  }

  // Get the user roles logged in
  isInRole(role) {
    let self = this
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function (user) {
        if (user == null) {
          self.signIn()
          return resolve(false)
        } else {
          const isIn = user.profile.roles.filter(s => s === role).length > 0
          return resolve(isIn)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      });
    })
  }

  getUserGroup() {
    let self = this
    return new Promise((resolve, reject) => {
      mgr.getUser().then(function (user) {
        if (user == null) {
          self.signIn()
          return resolve(false)
        } else {
          return resolve(user.profile.userGroup)
        }
      }).catch(function (err) {
        console.log(err)
        return reject(err)
      });
    })
  }
}
