import {defineStore} from "pinia";

export const useGlobalStore = defineStore('global', {
  state: () => (
    {
      sidebarVisible: true,
      sidebarUnfoldable: false,
      companyName: '',
      installationId: '',
      masterPwd: '',
      encKey: {},
      dataLocked: true
    }),
  actions: {
    toggleSidebar() {
      this.sidebarUnfoldable = !this.sidebarUnfoldable
    },
    burgerToggle() {
      if (this.sidebarUnfoldable) {
        this.sidebarUnfoldable = false;
        this.sidebarVisible = true;
      } else if (this.sidebarVisible) {
        this.sidebarVisible = false;
      } else if (!this.sidebarVisible) {
        this.sidebarVisible = true;
      }
    },
    updateSidebarVisible(payload) {
      if (payload && payload.value)
        this.sidebarVisible = (payload.value === true)
    },
  },
})
