export default [
  {
    component: 'CNavTitle',
    name: 'Record Keeping',
  },
  {
    component: 'CNavGroup',
    name: 'Movements',
    to: '/reports/latest',
    icon: 'cis-transfer-within-a-station',
    items: [
      {
        component: 'CNavItem',
        name: 'Latest Activities',
        to: '/reports/latest',
        icon: 'cil-sort-numeric-down',
      },
      {
        component: 'CNavItem',
        name: 'Personnel Onsite',
        to: '/reports/onsite',
        icon: 'cis-location-pin-check',
      },
      {
        component: 'CNavItem',
        name: 'Manual Entry',
        to: '/reports/manual',
        icon: 'cil-save',
      }
    ]
  },
  {
    component: 'CNavItem',
    name: 'Timesheet',
    to: '/reports/timesheet',
    icon: 'cis-spreadsheet',
  },
  {
    component: 'CNavItem',
    name: 'Payroll Export',
    to: '/reports/payroll',
    icon: 'cil-file-excel',
  },

  {
    component: 'CNavTitle',
    name: 'Projects',
  },
  {
    component: 'CNavItem',
    name: 'Job Sites',
    to: '/sites/overview',
    icon: 'cil-city',
  },

  {
    component: 'CNavTitle',
    name: 'Personnel',
  },
  {
    component: 'CNavItem',
    name: 'Employees',
    to: '/persons/overview',
    icon: 'cil-id-badge',
  },
  {
    component: 'CNavItem',
    name: 'Users',
    to: '/users/overview',
    icon: 'cil-id-card',
  },

  {
    component: 'CNavTitle',
    name: 'System',
  },
  {
    component: 'CNavItem',
    name: 'Devices',
    to: '/reports/devices',
    icon: 'cil-router',
  },
  {
    component: 'CNavItem',
    name: 'Settings',
    to: '/settings/',
    icon: 'cil-settings',
  },
]
